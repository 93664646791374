import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from '@mui/material/Checkbox'

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "75%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export const EditSpawnerConfigModal = ({
  open,
  handleClose,
  spawner,
  update,
}: {
  open: boolean;
  spawner: any;
  handleClose: () => void;
  update: (spwner: any) => Promise<void>;
}) => {
  if (!spawner) {
    spawner = {};
  }

  const name = React.useRef(null);
  const ip = React.useRef(null);
  const port = React.useRef(null);
  const region = React.useRef(null);
  const size = React.useRef(null);
  const qPortsMin = React.useRef(null);
  const qPortsMax = React.useRef(null);
  const gPortsMin = React.useRef(null);
  const gPortsMax = React.useRef(null);
  const warM = React.useRef(null);
  const warX = React.useRef(null);
  const tdmM = React.useRef(null);
  const tdmX = React.useRef(null);
  const hvtS = React.useRef(null);
  const breM = React.useRef(null);
  const fubM = React.useRef(null);
  const kiaS = React.useRef(null);
  const domM = React.useRef(null);
  const domX = React.useRef(null);
  const tagM = React.useRef(null);
  const gunM = React.useRef(null);
  const serverToCoreAssignment = React.useRef(null)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {spawner.name || "Add new spawner"}
        </Typography>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            // display: "flex",
            // flexDirection: "column",
          }}
          //   noValidate
          autoComplete="off"
        >
          {!spawner.name && (
            <TextField
              id="outlined-basic"
              label="Name"
              defaultValue={spawner.name || null}
              disabled={spawner.name && true}
              variant="outlined"
              inputRef={name}
            />
          )}
          <TextField
            id="outlined-basic"
            label="IP"
            defaultValue={spawner.ip || null}
            disabled={spawner.ip && true}
            variant="outlined"
            inputRef={ip}
          />
          <TextField
            id="outlined-basic"
            label="Port"
            defaultValue={spawner.port || null}
            disabled={spawner.port && true}
            variant="outlined"
            inputRef={port}
          />
          <Select
            label="Region"
            defaultValue={spawner.region || "EU"}
            disabled={spawner.region && true}
            inputRef={region}
          >
            <MenuItem value={"EU"}>EU</MenuItem>
            <MenuItem value={"US_WEST"}>US_WEST</MenuItem>
            <MenuItem value={"US_EAST"}>US_EAST</MenuItem>
            <MenuItem value={"UAE"}>UAE</MenuItem>
            <MenuItem value={"SGP"}>SGP</MenuItem>
          </Select>
          <TextField
            label="Size"
            type="text"
            // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            defaultValue={spawner.size || null}
            variant="outlined"
            inputRef={size}
          /><br />
          <Checkbox defaultChecked={spawner.serverToCoreAssignment ?? false} inputRef={serverToCoreAssignment}/> Server to core assignment
          <br />
          {/*<TextField*/}
          {/*  id="outlined-basic"*/}
          {/*  label="Additional ars"*/}
          {/*  defaultValue={""}*/}
          {/*  disabled={true}*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
          <FormGroup
            style={{ display: "flex", flexDirection: "row", width: "90%" }}
          >
            Game ports
            <TextField
              label="Min"
              defaultValue={spawner.gamePorts?.min || 0}
              disabled={spawner.gamePorts && true}
              variant="outlined"
              inputRef={gPortsMin}
            />
            <TextField
              label="Max"
              defaultValue={spawner.gamePorts?.max || 0}
              disabled={spawner.gamePorts && true}
              variant="outlined"
              inputRef={gPortsMax}
            />
          </FormGroup>
          <FormGroup sx={{ m: 1 }}>
            Query ports
            <TextField
              label="Min"
              defaultValue={spawner.queryPorts?.min || 0}
              disabled={spawner.gamePorts && true}
              variant="outlined"
              inputRef={qPortsMin}
            />
            <TextField
              label="Max"
              defaultValue={spawner.queryPorts?.max || 0}
              disabled={spawner.gamePorts && true}
              variant="outlined"
              inputRef={qPortsMax}
            />
          </FormGroup>
          <FormGroup sx={{ m: 1 }}>
            Game Modes
            <TextField
              label="WAR_M"
              defaultValue={spawner.supportedGameModes?.WAR_M || 0}
              variant="outlined"
              inputRef={warM}
            />
            <TextField
              label="WAR_X"
              defaultValue={spawner.supportedGameModes?.WAR_X || 0}
              variant="outlined"
              inputRef={warX}
            />
            <TextField
              label="TDM_M"
              defaultValue={spawner.supportedGameModes?.TDM_M || 0}
              variant="outlined"
              inputRef={tdmM}
            />
            <TextField
              label="TDM_X"
              defaultValue={spawner.supportedGameModes?.TDM_X || 0}
              variant="outlined"
              inputRef={tdmX}
            />
            <TextField
              label="HVT_S"
              defaultValue={spawner.supportedGameModes?.HVT_S || 0}
              variant="outlined"
              inputRef={hvtS}
            />
            <TextField
              label="BRE_M"
              defaultValue={spawner.supportedGameModes?.BRE_S || 0}
              variant="outlined"
              inputRef={breM}
            />
            <TextField
              label="FUB_M"
              defaultValue={spawner.supportedGameModes?.FUB_M || 0}
              variant="outlined"
              inputRef={fubM}
            />
            <TextField
              label="KIA_S"
              defaultValue={spawner.supportedGameModes?.KIA_S || 0}
              variant="outlined"
              inputRef={kiaS}
            />
            <TextField
              label="DOM_M"
              defaultValue={spawner.supportedGameModes?.DOM_M || 0}
              variant="outlined"
              inputRef={domM}
            />
            <TextField
              label="DOM_X"
              defaultValue={spawner.supportedGameModes?.DOM_X || 0}
              variant="outlined"
              inputRef={domX}
            />
            <TextField
              label="TAG_M"
              defaultValue={spawner.supportedGameModes?.TAG_X || 0}
              variant="outlined"
              inputRef={tagM}
            />
            <TextField
              label="GUN_M"
              defaultValue={spawner.supportedGameModes?.GUN_M || 0}
              variant="outlined"
              inputRef={gunM}
            />
          </FormGroup>
          <Button
            onClick={() => {
              console.log(serverToCoreAssignment.current.value)

              const newSpawnerConfig = {
                ip: ip.current.value,
                port: parseInt(port.current.value),
                size: parseInt(size.current.value),
                hostname: "remoteSpawner",
                name: name.current?.value || spawner.name,
                region: region.current.value,
                queryPorts: {
                  min: parseInt(qPortsMin.current.value),
                  max: parseInt(qPortsMax.current.value),
                },
                gamePorts: {
                  min: parseInt(gPortsMin.current.value),
                  max: parseInt(gPortsMax.current.value),
                },
                supportedGameModes: {
                  WAR_M: warM.current.value.toLowerCase() === 'true',
                  WAR_X: warX.current.value.toLowerCase() === 'true',
                  TDM_M: tdmM.current.value.toLowerCase() === 'true',
                  TDM_X: tdmX.current.value.toLowerCase() === 'true',
                  HVT_S: hvtS.current.value.toLowerCase() === 'true',
                  BRE_M: breM.current.value.toLowerCase() === 'true',
                  FUB_M: fubM.current.value.toLowerCase() === 'true',
                  KIA_S: kiaS.current.value.toLowerCase() === 'true',
                  DOM_M: domM.current.value.toLowerCase() === 'true',
                  DOM_X: domX.current.value.toLowerCase() === 'true',
                  TAG_M: tagM.current.value.toLowerCase() === 'true',
                  GUN_M: gunM.current.value.toLowerCase() === 'true',
                },
                serverToCoreAssignment: serverToCoreAssignment.current.checked
              };
              newSpawnerConfig["version"] = spawner?.version || "1.0.1";
              update(newSpawnerConfig);
              console.log(newSpawnerConfig);
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
